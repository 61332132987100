import { WithTranslation, withTranslation } from 'react-i18next';
import { Link } from 'react-router';

import NotificationList from '@components/UserNotifcation/NotificationList';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import useStore from '@stores/store';
import { useShallow } from 'zustand/react/shallow';

const NotificationModal = ({ t: trans }: WithTranslation) => {
  const { user, closeModal } = useStore(
    useShallow((state) => ({
      user: state.user,
      closeModal: state.closeModal,
    })),
  );

  return (
    <Box display="flex" flexDirection="column" width={500}>
      <Card
        elevation={0}
        variant="outlined"
        sx={{
          height: 'calc(100% - 32px)',
        }}
      >
        <CardHeader
          avatar={
            <Avatar
              sx={{
                backgroundColor: '#feeef0',
                color: 'redActual',
              }}
            >
              {user.initials}
            </Avatar>
          }
          title={user.fullName}
          subheader={user.job}
        />
        <CardContent
          sx={{
            height: 'calc(100% - 72px)',
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
          }}
        >
          <Box
            display="flex"
            flexGrow={1}
            overflow="auto"
            mb={1}
            width="100%"
            justifyContent="center"
          >
            <NotificationList />
          </Box>
        </CardContent>
      </Card>
      <Box textAlign="center" mt={2}>
        <Button
          component={Link}
          to="/manager/user-notifications"
          onClick={closeModal}
          color="primary"
          sx={{
            textTransform: 'none',
          }}
        >
          {trans('user_notification.list.all')}
        </Button>
      </Box>
    </Box>
  );
};

export default withTranslation()(NotificationModal);
