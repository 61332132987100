import { MouseEvent as ReactMouseEvent } from 'react';
import { Link } from 'react-router';

import { container } from '@_plugins/ioc';
import useTrainingCourseRetriever from '@components/TrainingCourse/hooks/use-training-course-retriever.hook';
import { EditAction, EditStep } from '@data/models/training-course.interface';
import { UserNotification } from '@data/models/user-notification.interface';
import UserNotificationService from '@data/services/user-notification.service';
import { faCircle, faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { IconProvider, Tooltip } from '@groupeactual/ui-kit';
import { getTrainingCourseUrl } from '@helpers/router.helper';
import { getPriorityColor } from '@helpers/training-course-priority.helper';
import Chip from '@mui/material/Chip';
import Icon from '@mui/material/Icon';
import MuiLink from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import useStore from '@stores/store';
import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import { useShallow } from 'zustand/react/shallow';

import NotificationIcon from './UserNotificationIcon';

const Notification = ({
  userNotification,
  t: trans,
}: {
  userNotification: UserNotification;
  t: TFunction;
}): JSX.Element => {
  const { updateNotification } = useStore(
    useShallow((state) => ({
      updateNotification: state.updateNotification,
    })),
  );

  const ListItemLink = () => {
    switch (userNotification.notification_type) {
      case 1:
      case 2:
        return getTrainingCourseUrl(
          userNotification.trainingCourseRef,
          undefined,
          EditAction.FORM,
        );
      case 3:
        return getTrainingCourseUrl(
          userNotification.trainingCourseRef,
          EditStep.ESTIMATOR,
          EditAction.FORM,
          userNotification.estimatorID,
        );
      case 4:
      case 5:
        return getTrainingCourseUrl(
          userNotification.trainingCourseRef,
          undefined,
          EditAction.FORM,
          undefined,
          true,
        );
      case 6:
      case 7:
        return getTrainingCourseUrl(
          userNotification.trainingCourseRef,
          undefined,
          EditAction.FORM,
          undefined,
          undefined,
          true,
        );
      case 8:
        return getTrainingCourseUrl(
          userNotification.trainingCourseRef,
          undefined,
          EditAction.HISTORY,
        );
      default:
        return '';
    }
  };

  const userNotificationService = container.get<UserNotificationService>(
    UserNotificationService,
  );

  const toggleNotificationRead = (
    e?: ReactMouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (e) {
      e.preventDefault();
    }
    userNotificationService.toggleReadAt(userNotification);

    updateNotification({
      ...userNotification,
      is_read: !userNotification.is_read,
    });
  };
  const { trainingCourse } = useTrainingCourseRetriever(
    userNotification.trainingCourseRef.split('#')[1],
  );

  return (
    <MuiLink
      component={Link}
      to={ListItemLink()}
      onClick={() => toggleNotificationRead()}
      sx={{
        textDecoration: 'none',
        color: 'greyXDark',
      }}
    >
      <ListItem
        disablePadding={true}
        alignItems="flex-start"
        sx={{
          py: 1,
          borderBottom: '1px solid #E3E3E3',
        }}
      >
        <ListItemAvatar sx={{ mt: 0 }}>
          <NotificationIcon type={userNotification.notification_type} />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography
              variant="body1"
              color="greyXDark"
              fontWeight="600"
              lineHeight="1"
            >
              {userNotification.title}
            </Typography>
          }
          secondary={
            <span style={{ color: '#545352', fontSize: '0.875rem' }}>
              <span>{userNotification.message}</span>
              <br />
              <span style={{ paddingTop: '0.5em' }}>
                {dayjs(userNotification.createdAt).format('LLL')}
                {userNotification.notification_type === 2 && (
                  <Chip
                    component="span"
                    size="small"
                    label={trans(
                      `dashboard.stats.priority.${String(
                        trainingCourse?.priority,
                      )}`,
                    )}
                    sx={[
                      getPriorityColor(trainingCourse?.priority),
                      {
                        marginLeft: '0.5em',
                        paddingTop: '0.3em',
                      },
                    ]}
                  />
                )}
                {(userNotification.notification_type === 1 ||
                  userNotification.notification_type === 2) &&
                  userNotification.isTrainingOrganismContacted && (
                    <Chip
                      component="span"
                      size="small"
                      label={trans(
                        'dashboard.notification.is_training_organism_contacted',
                      )}
                      sx={[
                        getPriorityColor('medium'),
                        {
                          marginLeft: '5.5em',
                        },
                      ]}
                    />
                  )}
              </span>
            </span>
          }
        />

        <ListItemIcon sx={{ pl: 4 }}>
          <Tooltip
            onClick={(e) => toggleNotificationRead(e)}
            title={
              trans(
                (userNotification.is_read &&
                  'user_notification.list.mark-as-unread') ||
                  'user_notification.list.mark-as-read',
              ) || ''
            }
          >
            <Icon>
              {(userNotification.is_read && (
                <IconProvider color="blueInfo" size="md" icon={faCircle} />
              )) || (
                <IconProvider color="blueInfo" size="md" icon={faCircleCheck} />
              )}
            </Icon>
          </Tooltip>
        </ListItemIcon>
      </ListItem>
    </MuiLink>
  );
};

export default Notification;
