import { useLocation } from 'react-router';

import { getRouteByPathname } from '@_plugins/router/router';
import RepositorySkeleton from '@components/Skeleton/RepositorySkeleton';

export const Loader = (): JSX.Element => {
  const { pathname } = useLocation();

  const { skeleton } = getRouteByPathname(pathname) || {};

  return <>{skeleton || <RepositorySkeleton />}</>;
};
