import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router';

import { faFolder, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { IconProvider } from '@groupeactual/ui-kit';
import { Text } from '@groupeactual/ui-kit';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import MuiLink from '@mui/material/Link';
import Link from '@mui/material/Link';

import DelegationTable from './components/DelegationTable';

const DelegationList = ({ t: trans }: WithTranslation): JSX.Element => {
  return (
    <div>
      <Box mt={4} display="flex" alignItems="center">
        <Box mr={2}>
          <IconProvider icon={faFolder} color="redActual" />
        </Box>
        <Box flexGrow={1} whiteSpace="nowrap">
          <Text component="h1" variant="header4">
            {trans('delegation.delegation_management')}
          </Text>
        </Box>
        <Grid container item justifyContent="flex-end">
          <Box display="flex" alignContent="center">
            <Button
              variant="contained"
              color="primary"
              startIcon={
                <IconProvider icon={faPlus} color="backgroundColorsWhite" />
              }
              disableElevation
            >
              <MuiLink
                component={RouterLink}
                sx={{
                  textDecoration: 'none',
                  color: 'background.paper',
                }}
                to="/delegation/add"
              >
                {trans('delegation.create_delegation')}
              </MuiLink>
            </Button>
          </Box>
        </Grid>
      </Box>
      <Box display="flex">
        <Breadcrumbs aria-label="breadcrumb" sx={{ fontSize: '13px' }}>
          <Link
            color="inherit"
            to="/"
            component={RouterLink}
            sx={{ color: '#136cac' }}
          >
            {trans('navigation.links.dashboard')}
          </Link>
          <span>{trans('delegation.delegation_management')}</span>
        </Breadcrumbs>
      </Box>
      <Box mt={2}>
        <Card>
          <DelegationTable />
        </Card>
      </Box>
    </div>
  );
};

export default withTranslation()(DelegationList);
