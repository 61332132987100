import { withTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import Breadcrumb from '@components/Breadcrumb/Breadcrumb';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import * as Sentry from '@sentry/react';
import useStore from '@stores/store';
import { useShallow } from 'zustand/react/shallow';

import Header from './Header';
import Router from './Router';
import TrainingHeader from './TrainingHeader';

const Body = () => {
  const { user } = useStore(
    useShallow((state) => ({
      user: state.user,
    })),
  );
  const location = useLocation();

  const { pathname } = location;

  const isRepository = pathname.startsWith('/configuration/repositories/');
  const isTraining = pathname.startsWith('/training');

  if (isRepository) {
    return (
      <>
        <Header />
        <Box display="flex" flexDirection="column" height="100%">
          <Box my={3}>
            <Container maxWidth="xl">
              <Breadcrumb />
            </Container>
          </Box>
          <Box flexGrow={1} position="relative">
            <Router user={user} />
          </Box>
        </Box>
      </>
    );
  }

  if (isTraining) {
    return (
      <>
        <TrainingHeader />
        <Box flexGrow={1} zIndex={1}>
          <Router user={user} />
        </Box>
      </>
    );
  }

  return (
    <>
      <Header />
      <Box display="flex" flexDirection="column" height="100%">
        <Box position="relative" flexGrow={1}>
          <Router user={user} />
        </Box>
      </Box>
    </>
  );
};

export default withTranslation()(Sentry.withProfiler(Body, { name: 'Body' }));
