import React, { Suspense, useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Location, Navigate, Route, Routes, useLocation } from 'react-router';

import {
  getRouteTransitionByPathname,
  getUserRoutes,
} from '@_plugins/router/router';
import PageTransition from '@components/Transition/PageTransition';
import { presets } from '@components/Transition/presets';
import { NavRoute } from '@data/models/navigation.interface';
import { User } from '@data/models/user.interface';
import Container from '@mui/material/Container';
import Error404 from '@pages/Error/404';
import * as Sentry from '@sentry/react';

import { Footer } from '.';
import { Loader } from './Loader';

// Utiliser SentryRoutes pour le monitoring
const SentryRoutes = Sentry.withSentryReactRouterV7Routing(Routes);

interface Props {
  user: User;
}

const Router = ({ user, t: trans }: Props & WithTranslation): JSX.Element => {
  const [previousLocation, setPreviousLocation] = useState<Location>();
  const [transition, setTransition] =
    useState<keyof typeof presets>('scaleDownScaleUp');

  const location = useLocation();
  const userRoutes = getUserRoutes(user);

  useEffect(() => {
    if (location.pathname === previousLocation?.pathname) {
      return;
    }

    setTransition(
      getRouteTransitionByPathname(
        location.pathname,
        previousLocation?.pathname,
      ),
    );
    setPreviousLocation(location);

    const { name } = userRoutes.find(
      ({ path }) => path === location.pathname,
    ) || {
      name: 'ALEC',
    };
    document.title = `ALEC - ${trans(name)}`;
  }, [location, setTransition, setPreviousLocation, userRoutes, trans]);

  if (!user.isAuthenticated) {
    return <></>;
  }

  const processSegment = (segment: string): string => {
    // Gère les segments avec paramètres optionnels
    if (segment.endsWith('?')) {
      return segment.slice(0, -1);
    }
    return segment;
  };

  const createRouteElement = (route: NavRoute) => {
    const segments = route.path.split('/').filter(Boolean);

    // Route racine spéciale
    if (route.path === '/') {
      return <Route key="root" path="/" element={route.component || <></>} />;
    }

    // Fonction récursive pour créer les routes imbriquées
    const createNestedRoute = (
      segments: string[],
      index: number = 0,
      basePath: string[] = [],
    ): JSX.Element => {
      if (index >= segments.length) {
        return <Route index element={route.component || <></>} />;
      }

      const currentSegment = processSegment(segments[index]);
      const updatedPath = [...basePath, currentSegment];

      // Création d'une clé unique basée sur le chemin complet jusqu'au segment actuel
      const routeKey = `route-${route.name.replace(/\./g, '-')}-${updatedPath.join('-')}`;

      // Si c'est le dernier segment
      if (index === segments.length - 1) {
        return (
          <Route
            key={routeKey}
            path={currentSegment}
            element={route.component || <></>}
          />
        );
      }

      // Pour les segments intermédiaires
      return (
        <Route key={routeKey} path={currentSegment}>
          {createNestedRoute(segments, index + 1, updatedPath)}
        </Route>
      );
    };

    return createNestedRoute(segments);
  };

  return (
    <PageTransition
      preset={transition}
      transitionKey={(previousLocation || location).pathname}
    >
      <Container
        maxWidth="xl"
        sx={{
          display: 'grid',
          minHeight: 'calc(100% - 54px)',
          flexDirection: 'column',
          pb: 2,
        }}
      >
        <Suspense fallback={<Loader />}>
          <SentryRoutes location={location}>
            <Route
              path="/"
              element={
                <Navigate
                  to={userRoutes?.filter(({ hidden }) => !hidden)[0].path}
                />
              }
            />

            {userRoutes.map((route, index) => (
              <React.Fragment key={`route-wrapper-${route.name}-${index}`}>
                {createRouteElement(route)}
              </React.Fragment>
            ))}
            <Route path="*" element={<Error404 />} />
          </SentryRoutes>
        </Suspense>
      </Container>
      <Footer />
    </PageTransition>
  );
};

export default withTranslation()(Router);
