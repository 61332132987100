import React, { useEffect, useState } from 'react';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router';

import { container } from '@_plugins/ioc';
import InactivitySpy from '@components/InactivitySpy/InactivitySpy';
import Dialog from '@components/Modal/Modal';
import ToastList from '@components/Toast/ToastList';
import useApp from '@data/hooks/use-app.hook';
import UserNotificationService from '@data/services/user-notification.service';
import Body from '@layout/Body';
import Box from '@mui/material/Box';
import * as Sentry from '@sentry/react';
import useStore from '@stores/store';
import { useShallow } from 'zustand/react/shallow';

Sentry.init({
  environment: 'production',
  dsn: 'https://0e7503d0a2534ed9927123d5afe5fcb6@o494482.ingest.sentry.io/5932289',
  // beforeSendTransaction(event: Sentry.Event) {
  //   console.log('beforeSendTransaction');
  //   const ignoredTransactions = [
  //     '/api/v1/user-notifications',
  //     'v1.user_notifications.index',
  //   ];
  //   if (event.transaction && ignoredTransactions.includes(event.transaction)) {
  //     return null;
  //   }
  //   return event;
  // },
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.reactRouterV7BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 1.0,
});

/**
 * Gestion du routing
 * Inclusion des régions
 */
const App = (): JSX.Element => {
  const [userNotificationLoaded, setUserNotificationLoaded] = useState(false);

  const { user, setNotifications, setInactive } = useStore(
    useShallow((state) => ({
      user: state.user,
      setNotifications: state.setNotifications,
      setInactive: state.setInactive,
    })),
  );

  const { getUser, getMinWage, getPecRate, getManagers, getDelegations } =
    useApp();

  const fetchMyNotifications = async () => {
    const userNotificationService = container.get<UserNotificationService>(
      UserNotificationService,
    );

    const userNotifications =
      await userNotificationService.listUserNotifications({
        page: 1,
        itemsPerPage: 50,
        orderBy: '-createdAt',
        filter: {
          user_email: 1,
        },
      });
    setNotifications(userNotifications.items);
  };

  useEffect(() => {
    setInactive(false);
    getUser();
    getMinWage();
    getPecRate();
    getManagers();
    getDelegations();
  }, []);

  useEffect(() => {
    if (
      userNotificationLoaded ||
      !user.permissions.includes('erpf.training-course.list')
    ) {
      return;
    }
    fetchMyNotifications();
    setInterval(() => fetchMyNotifications(), 1000 * 60 * 10);
    setUserNotificationLoaded(true);
  }, [
    fetchMyNotifications,
    userNotificationLoaded,
    setUserNotificationLoaded,
    user,
  ]);

  if (!user.isAuthenticated) {
    return <div className="spinner" />;
  }

  return (
    <Box
      height="100vh"
      display="flex"
      flexDirection="column"
      sx={{
        backgroundColor: 'greyXLight',
      }}
    >
      <BrowserRouter>
        <InactivitySpy />
        <Body />
        <Dialog />
        <ToastList />
      </BrowserRouter>
    </Box>
  );
};

export default App;
