import { useEffect, useRef, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate, useParams } from 'react-router';

import RegionSelectMultiple from '@components/Form/Widgets/Selects/RegionSelectMultiple';
import { toDelegationWizard } from '@data/dto/delegation.dto';
import { Delegation } from '@data/models/delegation.interface';
import { Manager } from '@data/models/manager.interface';
import { Region } from '@data/models/region.interface';
import DelegationService from '@data/services/delegation.service';
import ManagerService from '@data/services/manager.service';
import RegionService from '@data/services/region.service';
import { Text } from '@groupeactual/ui-kit';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import useStore from '@stores/store';
import { useInjection } from 'context/Ioc.context';
import { Form, Formik } from 'formik';
import { useShallow } from 'zustand/react/shallow';

import DelegationFormSkeleton from './components/DelegationFormSkeleton';
import SelectDateDelegation from './components/SelectDateDelegation';
import DelegationFormSchema from './schema/delegation-form.schema';

const DelegationWizard = ({ t: trans }: WithTranslation): JSX.Element => {
  const [regions, setRegions] = useState<Region[]>();
  const [delegation, setDelegation] = useState<Delegation>();
  const [managers, setManagers] = useState<Manager[]>();
  const { addToast, user } = useStore(
    useShallow((state) => ({
      addToast: state.addToast,
      user: state.user,
    })),
  );
  const regionsRef = useRef<HTMLInputElement>();
  const managerService = useInjection<ManagerService>(ManagerService);
  const delegService = useInjection<DelegationService>(DelegationService);
  const regionService = useInjection<RegionService>(RegionService);
  const navigate = useNavigate();

  const params = useParams();

  useEffect(() => {
    delegService.listDelegations({}).then((res) => {
      const delegation = res.items.filter((d) => d.id === params.ref);
      if (delegation.length) {
        setDelegation(delegation[0]);
      }
    });
  }, [params]);

  useEffect(() => {
    regionService.listRegions({}).then((res) => setRegions(res.items));
  }, []);

  const handleSubmit = (values: Delegation) => {
    delegService
      .upsertDelegation(toDelegationWizard(values))
      .then((updatedDelegation) => {
        if (!updatedDelegation) {
          return;
        }
        addToast({
          message: trans('delegation.action_edit'),
          level: 'success',
        });
        navigate('/delegation');
      });
  };

  useEffect(() => {
    managerService.listManagers({ include: ['Formation'] }).then((res) => {
      setManagers(res.items.filter((m) => m.id !== user.id));
    });
  }, []);

  return (
    <div>
      <Box mt={4} display="flex" alignItems="center">
        <Box flexGrow={1} whiteSpace="nowrap">
          <Text component="h1" variant="header4">
            {trans('delegation.edit_delegation')}
          </Text>
        </Box>
      </Box>

      <Box>
        <Breadcrumbs aria-label="breadcrumb" sx={{ fontSize: '13px' }}>
          <Link
            color="inherit"
            to="/"
            component={RouterLink}
            sx={{ color: '#136cac' }}
          >
            {trans('navigation.links.dashboard')}
          </Link>
          <Link
            sx={{ color: '#136cac' }}
            color="inherit"
            to="/delegation"
            component={RouterLink}
          >
            {trans('delegation.delegation_management')}
          </Link>
          <span>{trans('delegation.edit_delegation')}</span>
        </Breadcrumbs>
      </Box>
      {delegation && regions?.length ? (
        <Formik
          initialValues={delegation}
          validationSchema={DelegationFormSchema}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, setFieldValue, errors }) => {
            return (
              <Form>
                <Card
                  sx={{
                    marginTop: '14px',
                    border: '1px solid lightgrey',
                  }}
                >
                  <Grid
                    container
                    sx={{
                      display: 'flex',
                    }}
                  >
                    <Grid item xs={4}>
                      <Box
                        p={2}
                        sx={{
                          height: '100%',
                          backgroundColor: '#F0F0F0',
                          borderRight: '1px solid lightgrey',
                        }}
                      >
                        <FormControl fullWidth>
                          <InputLabel error={!!errors.manager}>
                            {trans('delegation.delegate')}
                          </InputLabel>
                          <Select
                            disabled
                            style={{
                              backgroundColor: '#ffff',
                            }}
                            label={trans('delegation.delegate')}
                            name={'manager'}
                            value={values.manager}
                            fullWidth
                            error={!!errors.manager}
                            onChange={handleChange}
                          >
                            {managers &&
                              managers.map((user: Manager, i: number) => {
                                return (
                                  <MenuItem key={i} value={user.id}>
                                    {user.fullName}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          {errors.manager && (
                            <FormHelperText
                              className="Mui-error"
                              sx={{
                                marginLeft: '0',
                              }}
                            >
                              {errors.manager}
                            </FormHelperText>
                          )}
                        </FormControl>
                        <FormControl
                          fullWidth
                          sx={{
                            marginTop: '24px',
                          }}
                        >
                          <InputLabel error={!!errors.delegationManager}>
                            {trans('delegation.representative')}
                          </InputLabel>
                          <Select
                            style={{
                              backgroundColor: '#ffff',
                            }}
                            label={trans('delegation.representative')}
                            name={'delegationManager'}
                            value={values.delegationManager}
                            fullWidth
                            error={!!errors.delegationManager}
                            onChange={handleChange}
                          >
                            {managers &&
                              managers.map((user: Manager, i: number) => {
                                return (
                                  <MenuItem key={i} value={user.id}>
                                    {user.fullName}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          {errors.delegationManager && (
                            <FormHelperText
                              className="Mui-error"
                              sx={{
                                marginLeft: '0',
                              }}
                            >
                              {errors.delegationManager}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid item xs={8}>
                      <Box
                        p={2}
                        sx={{
                          height: '100%',
                        }}
                      >
                        <SelectDateDelegation
                          label="dates"
                          error={{
                            fromDate: (errors.fromDate as string) || '',
                            toDate: (errors.toDate as string) || '',
                          }}
                          values={{
                            fromDate: values.fromDate || '',
                            toDate: values.toDate || '',
                          }}
                        />
                        <Box mt={3}></Box>
                        <RegionSelectMultiple
                          label={trans('delegation.regions')}
                          ref={regionsRef}
                          value={values.regions}
                          onChange={(regions: Region[]) => {
                            setFieldValue('regions', regions);
                          }}
                          error={errors.regions as string[]}
                          defaultOptions={regions}
                        />
                        <TextField
                          onChange={handleChange}
                          sx={{ marginTop: '20px' }}
                          value={values.reason}
                          label={values.reason ? '' : 'Motif'}
                          name="reason"
                          placeholder="Indiquez un motif"
                          multiline
                          rows={5}
                          variant="outlined"
                          fullWidth
                          error={!!errors.reason}
                        />
                        {errors.reason && (
                          <FormHelperText className="Mui-error">
                            {errors.reason}
                          </FormHelperText>
                        )}
                        <Grid container item justifyContent="flex-end" mt={4}>
                          <Box display="flex" alignContent="center">
                            <Button
                              variant="outlined"
                              color="primary"
                              disableElevation
                              type="button"
                              onClick={() => navigate('/delegation')}
                            >
                              {trans('action.cancel.label')}
                            </Button>
                            <Button
                              sx={{
                                marginLeft: '8px',
                              }}
                              variant="contained"
                              color="primary"
                              disableElevation
                              type="submit"
                            >
                              {trans('action.confirm.label')}
                            </Button>
                          </Box>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Card>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <DelegationFormSkeleton />
      )}
    </div>
  );
};

export default withTranslation()(DelegationWizard);
